import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {Form, Input, Schema, SelectPicker} from "rsuite";
import ShippingAddress from "../../../../../interfaces/ShippingAddress";
import PaymentAndPickupData from "../../../../../interfaces/PaymentAndPickupData";
import {useTranslation} from "react-i18next";
import {CarrierType} from "../../../../../enums/CarrierType";
import {DeliveryType} from "../../../../../enums/DeliveryType";
import velotradeDeliveryService from "../../../../../services/velotradeDeliveryService";
import {ItemDataType} from "rsuite/cjs/@types/common";
import {COUNTRY_LIST} from "../../models/countryList";
import deliveryAddressService from "../../../../../services/deliveryAddressService";
import GetDeliveryCountryModel from "../../../../../dto/city/GetDeliveryCountryModel";

interface SelectItem{
    label: string,
    value: string
}

const Field = React.forwardRef((props: any, ref: any) => {
    const { name, message, label, accepter, error, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control checkAsync={false} name={name} accepter={accepter} errorMessage={error} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

const DeliveryDpd:React.FC<{onChange: Function, shippingAddress?: ShippingAddress | null,
    onPaymentAndPickupDataChange?: (data: PaymentAndPickupData) => void,
    showPayOnDelivery: boolean}> = ({onChange, shippingAddress, onPaymentAndPickupDataChange, showPayOnDelivery}) => {
    const {t} = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const deliveryPayer = [{label: t('deliveryPayer.sender'), value: 0}, {label: t('deliveryPayer.receiver'), value: 1}]
    const [countryList, setCountryList] = useState<{ label: string, value: string}[]>([]);

    const [address, setAddress] = useState<ShippingAddress>({
        carrierType: CarrierType.DPD,
        deliveryType: DeliveryType.CourierDelivery,
        id: shippingAddress ? shippingAddress.id : null,
        state: shippingAddress ? shippingAddress.state : '',
        stateId:  shippingAddress ? shippingAddress.stateId : null,
        city: shippingAddress ? shippingAddress.city : '',
        cityId: shippingAddress ? shippingAddress.cityId : null,
        district: '',
        districtId: null,
        postalCode: null,
        street: shippingAddress ? shippingAddress.street : '',
        streetId: shippingAddress ? shippingAddress.streetId : null,
        house: shippingAddress ? shippingAddress.house : '',
        apartment: shippingAddress ? shippingAddress.apartment : '',
        carrierBranch: shippingAddress ? shippingAddress.carrierBranch : '',
        carrierBranchId: shippingAddress ? shippingAddress.carrierBranchId : null,
        country: shippingAddress ? shippingAddress.country : null,
        countryId: shippingAddress ? shippingAddress.countryId: null
    })

    const formRef = useRef<any>();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({
        area: shippingAddress?.stateId,
        city: shippingAddress?.cityId,
        branch: shippingAddress?.carrierBranchId
    });
    const [formValueCourier, setFormValueCourier] = useState({
        country: shippingAddress?.countryId,
        area: shippingAddress?.state,
        district: shippingAddress?.district,
        city: shippingAddress?.city,
        street: shippingAddress?.street,
        house: shippingAddress?.house,
        postalCode: shippingAddress?.postalCode,
        apartment: shippingAddress?.apartment
    });
    const [formErrorAddress, setFormErrorAddress] = useState({});

    const country = t('delivery.country')
    const area = t('delivery.area')
    const district = t('delivery.district')
    const city = t('delivery.city')
    const branch = t('delivery.branch')
    const postalCode = t('delivery.postalCode')
    const street = t('delivery.street')
    const house = t('delivery.house')
    const apartment = t('delivery.apartment')
    const payer = t('delivery.payer')
    const sum = t('delivery.sum')

    const { StringType } = Schema.Types;
    const errorField = t('form.requiredFieldMsg')

    const modelAddress = Schema.Model({
        country: StringType().isRequired(errorField),
        //area: StringType().isRequired(errorField),
        //district: StringType().isRequired(errorField),
        city: StringType().isRequired(errorField),
        street: StringType().isRequired(errorField),
        house: StringType().isRequired(errorField),
        postalCode: StringType().isRequired(errorField)
        //apartment: StringType().isRequired(errorField),
    });

    useEffect(() => {
        if (shippingAddress){
            setTabIndex(shippingAddress.deliveryType )
            saveDeliveryType(shippingAddress.deliveryType)
        }
    }, []);

    useEffect(() => {
        deliveryAddressService.getDeliveryCountries()
            .then(data => {
                setCountryList(data.map(i => { return {label: i.name, value: i.uid} }));
            });
    }, []);


    const countryChange = async (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): Promise<void> => {
        if (item.label && value) {
            setAddress({...address, country: item.label.toString(), countryId: value})
        }
    }

    const areaChange = (value: string , event: any) => {
        if (value) {
            setAddress({...address, state: value, stateId: value})
        }
    }

    const districtChange = (value: string , event: any) => {
        if (value) {
            setAddress({...address, district: value, districtId: value})
        }
    }

    const cityChange = (value: string , event: any) => {
        if (value) {
            setAddress({...address, city: value, cityId: value})
        }
    }

    /*const postalCodeChange = (value: string , event: any) => {
        if (value) {
            setAddress({...address, postalCode: value})
            setSelectedPostalCode(value)
        }
    }*/

    const postalCodeChange = (value: string , event: any) => {
        if (value) setAddress({...address, postalCode: value})
    }

    const streetChange = (value: string , event: any) => {

        if (value) {
            setAddress({...address, street: value, streetId: value})
        }

    }


    const [deliveryPayerVal, setDeliveryPayerVal] = useState<number | null>(null)
    const [orderAmountVal, setOrderAmountVal] = useState<number | null>(null)

    const deliveryPayerChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>) => {
        setDeliveryPayerVal(+value!)
        if(onPaymentAndPickupDataChange) {
            onPaymentAndPickupDataChange({
                deliveryPayer: +value!,
                orderToPay: orderAmountVal,
                carModel: null,
                carNumber: null,
                pickupDate: null,
                pickupTime: null
            })
        }
    }

    const setOrderAmount = (value: string , event: any) => {
        setOrderAmountVal(+value)
        if(onPaymentAndPickupDataChange) onPaymentAndPickupDataChange({
            deliveryPayer: deliveryPayerVal,
            orderToPay: +value,
            carModel: null,
            carNumber: null,
            pickupDate: null,
            pickupTime: null
        })

    }

    const houseChange = (value: string , event: any) => {
        if (value) setAddress({...address, house: value})
    }

    const apartmentChange = (value: string , event: any) => {
        if (value) setAddress({...address, apartment: value})
    }

    const [isSaved, setIsSaved] = useState(false)

    const saveData = () => {
        if (formRef.current!.check()) {
            onChange({...address})
            setIsSaved(true)
        }
    }

    const saveDeliveryType = (deliveryTypeEnum: number) => {
        setAddress(({...address, deliveryType: deliveryTypeEnum}))
    }

    const focusInput = (el: any) => {

        const inputs = document.getElementsByClassName('rs-picker-search-bar-input')
        for (let i = 0; i < inputs.length; i++) {
            const slide = inputs[i] as HTMLElement;
            slide.focus();
        }
    }
    return (
        <div className="delivery-services-item delivery-dpd-wrapper">
            <Form model={modelAddress} ref={formRef} onCheck={setFormErrorAddress} formDefaultValue={formValueCourier}>

                <div className={formError.hasOwnProperty('country') ? "input-wrapper selectpicker input-area has-error" : "input-wrapper selectpicker input-area"}>
                    <Field
                        name="country"
                        label={country}
                        accepter={SelectPicker}
                        data={countryList}
                        /*value={selectedCountry}*/
                        onSelect={countryChange}
                        onOpen={focusInput}
                        searchable={false}
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('area') ? "input-wrapper input-area has-error" : "input-wrapper input-area"}>
                    <Field
                        name="area"
                        label={area}
                        /*value={selectedArea}*/
                        accepter={Input}
                        onChange={areaChange}
                        /*onOpen={focusInput}*/
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('district') ? "input-wrapper input-district has-error" : "input-wrapper input-district"}>
                    <Field
                        name="district"
                        label={district}
                        /*value={selectedDistrict}*/
                        accepter={Input}
                        onChange={districtChange}
                        /*onOpen={focusInput}*/
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('city') ? "input-wrapper input-city has-error" : "input-wrapper input-city"}>
                    <Field
                        name="city"
                        label={city}
                        /*value={selectedCity}*/
                        accepter={Input}
                        onChange={cityChange}
                        /*onOpen={focusInput}*/
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('postalCode') ? "input-wrapper input-postal-code has-error" : "input-wrapper input-postal-code"}>
                    <Field
                        name="postalCode"
                        label={postalCode}
                        /*value={selectedPostalCode}*/
                        accepter={Input}
                        data={postalCode}
                        onChange={postalCodeChange}
                        /*onOpen={focusInput}*/
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('street') ? "input-wrapper input-street has-error" : "input-wrapper input-street"}>
                    <Field
                        name="street"
                        label={street}
                        accepter={Input}
                        /*value={selectedStreet}*/
                        onChange={streetChange}
                        /*onOpen={focusInput}*/
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('house') ? "input-wrapper input-house has-error" : "input-wrapper input-house"}>
                    <Field
                        name="house"
                        label={house}
                        //value={address.house}
                        accepter={Input}
                        onChange={houseChange}
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('apartment') ? "input-wrapper input-apartment has-error" : "input-wrapper input-apartment"}>
                    <Field
                        name="apartment"
                        label={apartment}
                        //value={address.apartment}
                        accepter={Input}
                        onChange={apartmentChange}
                    />
                </div>

                {onPaymentAndPickupDataChange ? <div className="input-wrapper selectpicker input-payer">
                    <Field
                        name="payer"
                        label={payer}
                        accepter={SelectPicker}
                        searchable={false}
                        data={deliveryPayer}
                        onSelect={deliveryPayerChange}
                        onOpen={focusInput}
                    />
                </div>: null}

                {onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="input-wrapper input-sum">
                    <Field
                        name="sum"
                        label={sum}
                        accepter={Input}
                        onChange={setOrderAmount}
                    />
                </div>: null}
                {onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="pay-method-info">
                    <p>{t('order.payMethod')}</p>
                </div>: null}

                <div className="btn-wrapper">
                    <button
                        disabled={Object.keys(formErrorAddress).length ? true: false}
                        type="submit"
                        className="btn btn-lg"
                        onClick={saveData}>
                        {t('order.save')}
                    </button>
                </div>
            </Form>
            {/*{isSaved ? null : <Tabs selectedIndex={tabIndex}> onSelect={(index) => changeTab(index)}
                <TabList>
                    <Tab onClick={() => saveDeliveryType(1)}>{t('delivery.addressDelivery')}</Tab>
                </TabList>

                <TabPanel>

                </TabPanel>

            </Tabs>}*/}
        </div>
    )
}

export default DeliveryDpd